.service-area {
    margin-top: 100px;
    margin-bottom: 100px;
    transition: all 0.5s;
}

.service-area a {
    text-decoration: none;
}

.service-card {
    margin-top: 20px;
    padding: 2rem;
    border-radius: 5px;
    filter: grayscale(1);
}

.service-card:hover {
    box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.03);
    background-color: #6DB3F2;
    cursor: default;
    filter: grayscale(0.7);
}

.service-card img {
    width: 100px;
}

.service-card h4 {
    font-weight: 600;
    font-size: 20px;
}

.service-card h4, .service-card p {
    color: white;
    text-shadow: 1px 1px 5px black;
}