@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap);
html {
    scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
* {
	margin: 0;
	padding: 0;
	font-family: 'Poppins', sans-serif;
}

body {
	color: #111430;
}

.common-heading h3 {
	font-size: 34px;
	font-weight: 600;
	text-align: center;
}

.common-heading h3 span {
	color: #7ab259;
}

.navigation-bar .navbar-light .navbar-toggler {
	color: #fff;
	border-color: transparent;
	outline: none;
}

.navigation-bar .navbar-light .navbar-toggler-icon {
	/* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255. 255. 255, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); */
	font-size: 24px;
	-webkit-filter: invert(1);
	        filter: invert(1);
}

/* Extra small devices (portrait phones, less than 576px) */

@media (max-width: 575.98px) {
	.home .header-area {
		min-height: auto;
		padding-bottom: 20px;
	}
	.header-area::after {
		display: none;
	}
	.banner-area {
		margin-top: 20px;
	}
	.banner-text {
		padding: 0px 20px;
	}
	.banner-area .banner-text h2 {
		font-size: 37px;
	}
	.brand-logos {
		margin: 20px 0px;
	}
}

/* Small devices (landscape phones, 576px and up) */

@media (min-width: 576px) and (max-width: 767.98px) {
	.home .header-area {
		min-height: auto;
		padding-bottom: 20px;
	}
	.header-area::after {
		display: none;
	}
	.brand-logos {
		margin: 20px 0px;
	}
	.dashboard-home .order-area input,
	.dashboard-home .order-area textarea {
		width: 100%;
	}
	.dashboard-home .order-area input[type='number'],
	.dashboard-home .order-area input[type='file'] {
		width: 30%;
		display: inline-block;
	}
}

/* Medium devices (tablets, 768px and up) */

@media (min-width: 768px) and (max-width: 991.98px) {
	.header-area::after {
		display: none;
	}
	.banner-img {
		margin: 30px 0px;
	}
	.brand-logos {
		margin: 30px 0px;
	}
	.dashboard-home .order-area input,
	.dashboard-home .order-area textarea {
		width: 90%;
	}
	.dashboard-home .order-area input[type='number'],
	.dashboard-home .order-area input[type='file'] {
		width: 30%;
		display: inline-block;
	}
}

/* Large devices (desktops, 992px and up) */

@media (min-width: 992px) and (max-width: 1199.98px) {
	.dashboard-home .order-area input,
	.dashboard-home .order-area textarea {
		width: 70%;
	}
	.dashboard-home .order-area input[type='number'],
	.dashboard-home .order-area input[type='file'] {
		width: 30%;
		display: inline-block;
	}
}

/* Extra large devices (large desktops, 1200px and up) */

@media (min-width: 1200px) {
}

@media (max-width: 991.98px) {
	/* .navbar-nav .nav-link:last-child {
    text-align: center;
  } */
}

.brand-logos img {
	width: 130px;
	height: auto;
	-webkit-filter: grayscale(1);
	        filter: grayscale(1);
}

.brand-logos img:hover {
	-webkit-filter: opacity(0.5) grayscale(1);
	        filter: opacity(0.5) grayscale(1);
}

.bottom {
	margin-top: 80px;
}

.footer-area {
	/* background-color: #1069ad; */
	background: rgb(9 36 56);
	color: white;
	/* padding-top: 100px; */
	padding-bottom: 20px;
}

.footer-text h2 {
	font-weight: 700;
	font-size: 34px;
	letter-spacing: 0.5px;
}

.footer-text p {
	letter-spacing: 0.7px;
}

.footer-form input,
.footer-form textarea {
	width: 100%;
	border: 1px solid #bfbfbf;
	padding: 15px;
	margin: 5px;
}

.footer-form #checkbox {
	width: auto;
}

.footer-form textarea {
	height: 150px;
}

.footer-form button {
	background-color: #1069ad;
	color: #fff;
	border-radius: 5px;
	border: none;
	margin: 5px;
	padding: 10px 40px;
}

.footer-subtitle {
	font-size: 0.75rem;
}

.footer-subtitle > a {
	color: #fff;
}

.copyright-right-area {
	font-size: 14px;
}

.success {
	color: green;
}

.fail {
	color: red;
}

.banner-text h2 {
	font-size: 48px;
	font-weight: 700;
}

.banner-text p {
	line-height: 24px;
	letter-spacing: 1px;
	margin: 20px 0px;
}

.banner-text a {
	background-color: #1069ad;
	color: #fff;
	text-decoration: none;
	padding: 0.6rem 2rem;
	border-radius: 5px;
	display: inline-block;
}
.banner-text a:hover {
	background-color: #0f2b40;
	color: #fff;
	text-decoration: none;
	padding: 0.6rem 2rem;
	border-radius: 5px;
	display: inline-block;
}

.banner-image {
	-webkit-filter: brightness(10);
	        filter: brightness(10);
	height: 100%;
	max-width: 120%;
}

.navbar {
    padding: .5rem 0;
}

.navbar-brand img {
    width: 200px;
}

.navbar-light .navbar-nav .nav-link {
    color: white;
}

.navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
}

.navbar-expand-lg .navbar-nav .nav-link:focus {
    color: white
}

/* .navbar-nav .nav-link:last-child {
    background-color: #111430;
    color: #fff;
    padding-right: 2rem;
    padding-left: 2rem;
    border-radius: 5px;
} */

.navbar-nav .nav-link:hover {
    color: #1069ad !important;
}
.header-area {
    /* background-color: #1069ad; */
    background: linear-gradient(180deg, rgb(9 36 56) 0%, rgba(11,67,109,1) 100%);
    color: white;
    min-height: 87vh;
    position: relative;
    overflow: hidden;
}

.header-area::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: 0;
    border-right: 100vw solid #fff;
    border-top: 150px solid transparent;
    width: 0;
    z-index: 0;
}
.service-area {
    margin-top: 100px;
    margin-bottom: 100px;
    transition: all 0.5s;
}

.service-area a {
    text-decoration: none;
}

.service-card {
    margin-top: 20px;
    padding: 2rem;
    border-radius: 5px;
    -webkit-filter: grayscale(1);
            filter: grayscale(1);
}

.service-card:hover {
    box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.03);
    background-color: #6DB3F2;
    cursor: default;
    -webkit-filter: grayscale(0.7);
            filter: grayscale(0.7);
}

.service-card img {
    width: 100px;
}

.service-card h4 {
    font-weight: 600;
    font-size: 20px;
}

.service-card h4, .service-card p {
    color: white;
    text-shadow: 1px 1px 5px black;
}
.works-area {
	/* background-color: #111430; */
	padding: 100px 0;
}

.slide {
	padding: 10px;
	outline: none;
}

.slide img {
	-webkit-filter: grayscale(1);
	        filter: grayscale(1);
}

.slide img:hover {
	-webkit-filter: saturate(0.2);
	        filter: saturate(0.2);
}

.slick-dots li.slick-active button:before {
	opacity: 1;
	color: #1069ad;
}

.slick-dots li {
	margin: 0;
}

.slick-dots li button:before {
	opacity: 0.7;
	color: #242a65;
	width: 30px;
	height: 30px;
}

