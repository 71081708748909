.header-area {
    /* background-color: #1069ad; */
    background: linear-gradient(180deg, rgb(9 36 56) 0%, rgba(11,67,109,1) 100%);
    color: white;
    min-height: 87vh;
    position: relative;
    overflow: hidden;
}

.header-area::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: 0;
    border-right: 100vw solid #fff;
    border-top: 150px solid transparent;
    width: 0;
    z-index: 0;
}