.banner-text h2 {
	font-size: 48px;
	font-weight: 700;
}

.banner-text p {
	line-height: 24px;
	letter-spacing: 1px;
	margin: 20px 0px;
}

.banner-text a {
	background-color: #1069ad;
	color: #fff;
	text-decoration: none;
	padding: 0.6rem 2rem;
	border-radius: 5px;
	display: inline-block;
}
.banner-text a:hover {
	background-color: #0f2b40;
	color: #fff;
	text-decoration: none;
	padding: 0.6rem 2rem;
	border-radius: 5px;
	display: inline-block;
}

.banner-image {
	filter: brightness(10);
	height: 100%;
	max-width: 120%;
}
