.works-area {
	/* background-color: #111430; */
	padding: 100px 0;
}

.slide {
	padding: 10px;
	outline: none;
}

.slide img {
	filter: grayscale(1);
}

.slide img:hover {
	filter: saturate(0.2);
}

.slick-dots li.slick-active button:before {
	opacity: 1;
	color: #1069ad;
}

.slick-dots li {
	margin: 0;
}

.slick-dots li button:before {
	opacity: 0.7;
	color: #242a65;
	width: 30px;
	height: 30px;
}
