.bottom {
	margin-top: 80px;
}

.footer-area {
	/* background-color: #1069ad; */
	background: rgb(9 36 56);
	color: white;
	/* padding-top: 100px; */
	padding-bottom: 20px;
}

.footer-text h2 {
	font-weight: 700;
	font-size: 34px;
	letter-spacing: 0.5px;
}

.footer-text p {
	letter-spacing: 0.7px;
}

.footer-form input,
.footer-form textarea {
	width: 100%;
	border: 1px solid #bfbfbf;
	padding: 15px;
	margin: 5px;
}

.footer-form #checkbox {
	width: auto;
}

.footer-form textarea {
	height: 150px;
}

.footer-form button {
	background-color: #1069ad;
	color: #fff;
	border-radius: 5px;
	border: none;
	margin: 5px;
	padding: 10px 40px;
}

.footer-subtitle {
	font-size: 0.75rem;
}

.footer-subtitle > a {
	color: #fff;
}

.copyright-right-area {
	font-size: 14px;
}

.success {
	color: green;
}

.fail {
	color: red;
}
